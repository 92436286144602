// usePassword.js
import { useCallback, useEffect, useState } from "react";

function usePassword() {
    const [password, setPassword] = useState("");

    useEffect(() => {
        generatePassword();
    }, []);

    const generatePassword = useCallback(() => {
        let passLength = 8;

        const numbers = '123456789';
        const specialCharacters = '!@#$%&';
        const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';

        // Pick one random number, special character, and uppercase letter
        const randomNumber = numbers[Math.floor(Math.random() * numbers.length)];
        const randomSpecialCharacter = specialCharacters[Math.floor(Math.random() * specialCharacters.length)];
        const randomUpperCaseLetter = upperCaseLetters[Math.floor(Math.random() * upperCaseLetters.length)];

        // Fill the remaining with random lowercase letters
        let remainingCharacters = '';
        for (let i = 0; i < passLength - 3; i++) {
            remainingCharacters += lowerCaseLetters[Math.floor(Math.random() * lowerCaseLetters.length)];
        }

        // Combine all and shuffle to avoid fixed order
        let password = randomNumber + remainingCharacters;

        // Shuffle the password characters
        password = password.split('').sort(() => 0.5 - Math.random()).join('');
        let newPassword = `${randomUpperCaseLetter}${password}${randomSpecialCharacter}`;
        setPassword(newPassword)

    }, [password]);
    console.log(password, "passwords")
    return password;
}

export default usePassword;
/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { create } from 'actions/admin/user';
import Errors from '../../Notifications/Errors';
import Spinner from 'views/Spinner';
import randomstring from 'randomstring';
import { PASSWORD_DEFAULT_LENGTH } from 'constants/index';
import { Link, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormText
} from 'reactstrap';
import usePassword from 'utils/usePasswordHook';

const CreateUser = ({ create, history, errorList, loading, location }) => {
  /********************************generate password ********************/

  //   randomstring.generate(PASSWORD_DEFAULT_LENGTH)
  // );
  let navigate = useNavigate(); // make const
  const [checkPassword, setCheckPassword] = useState(false);

  /********************************usestate form data ********************/
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',

    //role: history.location.pathname === '/admin/staff/create' ? 5 : 2,
  });

  //   history.location.pathname === '/admin/staff/create' ? true : false;
  // /************************explode form data ********************/
  const { first_name, last_name, email, password } = formData;
  const inputRef = useRef(null);

  /***********************use dispatch ********************/

  /***********************on change event ********************/
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value?.trim() });
    setCheckPassword(false);
  };

  let genpass = usePassword();
  useEffect(() => {
    if (genpass) {
      setFormData({ ...formData, password: genpass })
      ReactTooltip.show(inputRef.current);
    }
  }, [genpass]);

  /***********************on submit event ********************/
  const onSubmit = (e) => {
    e.preventDefault();
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
    const isChecked = regex.test(password);
    if (isChecked) {
      create(formData, navigate);
    } else {
      setCheckPassword(true);
    }
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Form
          className="form-horizontal"
          autoComplete="false"
          onSubmit={(e) => onSubmit(e)}
        >
          <Row>
            <Col xs="12" sm="12">
              <Card>
                <CardHeader>
                  <h5>Basic Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="first_name">
                          First Name <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="first_name"
                          name="first_name"
                          maxLength="25"
                          value={first_name}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.first_name ? true : false}
                        />
                        <Errors current_key="first_name" key="first_name" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="last_name">Last Name</Label>
                        <Input
                          type="text"
                          id="last_name"
                          name="last_name"
                          maxLength="25"
                          value={last_name}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.last_name ? true : false}
                        />
                        <Errors current_key="last_name" key="last_name" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="email">
                          Email <span>*</span>
                        </Label>
                        <Input
                          type="email"
                          id="email"
                          name="email"
                          maxLength="50"
                          value={email}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.email ? true : false}
                        />
                        <Errors current_key="email" key="email" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="password">
                          Password <span>*</span>
                        </Label>
                        {password == '' &&
                          <ReactTooltip id="title required" efect="solid" place="bottom">
                            Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                          </ReactTooltip>
                        }
                        <Input
                          type="text"
                          id="password"
                          name="password"
                          ref={inputRef}
                          data-tip
                          data-for="title required"
                          value={password}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.password ? true : false}
                        />
                        <Errors current_key="password" key="password" />
                        {(checkPassword && password != "") &&
                          <div id="message">
                            <FormText style={{ fontSize: "10px" }} className='text-danger'> Password requirements:</FormText>
                            <FormText>
                              <li style={{ fontSize: "10px" }} className='text-danger'>Must contain at least 8 characters</li>
                              <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 uppercase letter</li>
                              <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 number</li>
                              <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 special character (@$!%*#?&.)</li>
                            </FormText>
                          </div>
                        }
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* {showRoles ? (
                    <>
                      <FormGroup>
                        <Label htmlFor="role">Role</Label>
                        <Input
                          type="select"
                          name="role"
                          id="role"
                          onChange={(e) => selectRole(e)}
                        >
                          {Object.keys(ACCESS_ROLE).map((item) => item === 'ADMIN' ? null : <option value={item}>{item}</option>
                          )}
                        </Input>
                      </FormGroup>
                    </>
                  ) : null} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardFooter>
                  <Button
                    className="mr-05"
                    type="submit"
                    size="sm"
                    color="primary"
                    disabled={loading}
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/admin/customers">
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

CreateUser.propTypes = {
  create: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user,
  loading: state.user.loading,
  errorList: state.errors,
});

export default connect(mapStateToProps, { create })(CreateUser);

export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE_SIZE_SECOND_OPTION = 40;
export const TABLE_BORDER_COLOR = '#f1f5f1';
export const IMAGE_MAX_SIZE = 1000000000; //bytes
export const ACCEPTED_IMAGE_TYPES =
  'image/png, image/jpg, image/jpeg';
export const ACCEPTED_IMAGE_TYPES_ARRAY = ACCEPTED_IMAGE_TYPES.split(
  ','
).reduce((obj, item) => {
  return { ...obj, [item.trim()]: [] };
}, {});

export const PRODUCT_IMAGE_LIMIT = 100;

export const DEALER_LOCATION_COUNTRY = 'United States';

export const SHIPPING_BOX_SIZE_UNIT = '(in)';

export const SHIPPING_BOX_WEIGHT_UNIT = '(lb)';

export const ATTRIBUTE_WEIGHT_UNIT = '(g)';

export const SHIPPO_ADDRESS_FROM = {
  name: 'Kinetixx',
  company: 'KINETIXX TECHNOLOGY INC.',
  street1: '1060 Calle Negocio',
  street2: 'Suite E',
  city: 'San Clemente',
  state: 'CA',
  zip: '92673',
  country: 'USA',
  // "ccode": "+1",
  phone: '+19494410154',
  email: 'admin@yopmail.com',
};

export const DEALER_APPROVED_STATUS = {
  0: 'Pending',
  1: 'Deactivated',
  2: 'Active',
  3: 'Rejected',
};

export const DISPLAYED_DEALER_APPROVED_STATUS = {
  0: 'Pending',
  1: 'Deactivated',
  2: 'Active',
  3: 'Rejected',
};

export const USER_APPROVED_STATUS = {
  0: 'Pending',
  1: 'Inactive',
  2: 'Active',
  3: 'Blocked',
};

export const DISPLAYED_USER_APPROVED_STATUS = {
  0: 'Pending',
  1: 'Inactivate',
  2: 'Active',
  3: 'Block',
};

export const ACCESS_ROLE = {
  ADMIN: 1,
  STAFF: 5,
};

export const USER_ROLE = {
  1: 'ADMIN',
  2: 'CUSTOMER',
  3: 'DEALER',
  4: 'GUEST USER',
  5: 'STAFF',
};

export const ORDER_CREATE_FOR_USER_ROLE = {
  2: 'CUSTOMER',
  3: 'DEALER',
};

export const PRODUCT_STATUS = {
  0: 'Pending',
  1: 'Save As Draft',
  2: 'Published',
  3: 'Blocked',
};

export const ADMIN_PRODUCT_STATUS = {
  1: 'Save As Draft',
  2: 'Published',
  3: 'Blocked',
  4: "Internal Product",
};

export const COUPON_DEFAULT_LENGTH = 8;
export const PASSWORD_DEFAULT_LENGTH =
  Math.floor(Math.random() * (10 - 6 + 1)) + 6; // 10 >= length >= 6

/***********************FRONT PANEL CONSTANTS ***************/
export const HEADER_TYPE = {
  VIDEO: 1,
  IMAGE: 2,
  NONE: 3,
};
export const PRODUCT_PAGE_SIZE = 10;

export const SETTINGS_KEY = [
  'header_script',
  'footer_script',
  'copyright_text',
];

export const SOCIAL_SETTINGS_KEY = [
  'facebook',
  'instagram',
  'linkedIn',
  'twitter',
];

export const CARRIER_SETTINGS_KEY = [
  'fedex',
  'ups',
  'dhl'
]

export const ALLOW_BACK_ORDERS = {
  1: 'Do not allow',
  2: 'Allow to purchase',
  // "3": "Allow with notifying to customer",
};

export const CART_EXPIRATION_TIME_LIMIT = 24 * 60 * 60 * 1000;

export const CART_PRODUCT_COUNT_OPTIONS = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: '10+',
};

export const ADDRESS_TYPE = {
  BILLING: 1,
  SHIPPING: 2,
};

export const PAYMENT_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
};

export const CUSTOMER_TYPE = {
  ALL: 1,
  CUSTOMER: 2,
  DEALER: 3,
};

export const PRODUCT_RATING_RANGE = {
  1: ' 1 Star ',
  2: ' 2 Star ',
  3: ' 3 Star ',
  4: ' 4 Star ',
  5: ' 5 Star ',
};

export const ORDER_LIST_PAYMENT_STATUS = {
  1: 'PENDING',
  2: 'PAID',
  3: "NET05",
  4: "NET15",
  5: "NET30",
  6: "NET45",
  7: "NET60",
  8: "NET90"
  //3: 'FAILED',
  // 4: "INCOMPLETE",
  //4: 'CANCELLED',
};

export const ORDER_PAYMENT_STATUS = {
  PENDING: 1,
  SUCCESS: 2,
  FAILED: 3,
  // INCOMPLETE: 4,
  CANCELLED: 4,
};

export const USER_TYPE = {
  ADMIN: 1,
  CUSTOMER: 2,
  DEALER: 3,
  NON_REGISTERED: 4,
  STAFF: 5,
};

export const FRONT_PRODUCT_ORDER_BY = {
  1: 'Sort By',
  2: 'Price (Low to High)',
  3: 'Price (High to Low)',
  4: 'Ratings',
};

export const MIN_PRICE_RANGE_PRODUCT_LISTING = 0;
export const MAX_PRICE_RANGE_PRODUCT_LISTING = 10000;
export const DEFAULT_PRICE_RANGE_STEP = 2000;

export const PERMISSION_NAME = {
  ORDER_MANAGEMENT: 1,
  PRODUCT_MANAGEMENT: 2,
  SHIPPING_MANAGEMENT: 3,
  CUSTOMER_MANAGEMENT: 4,
  CMS_MANAGEMENT: 5,
  SUPER_ADMIN: 6,
  BUILD_SHOP_MANAGEMENT: 7,
};
export const UI_PERMISSION_NAME = {
  1: 'ORDER MANAGEMENT',
  2: 'PRODUCT MANAGEMENT',
  3: 'SHIPPING MANAGEMENT',
  4: 'CUSTOMER MANAGEMENT',
  5: 'CMS MANAGEMENT',
  6: 'SUPER ADMIN',
  7: 'BUILD SHOP MANAGEMENT',
};

export const ADDRESS_STATUS = {
  0: 'Pending',
  1: 'Active',
  2: 'Inactive',
};

export const USER_STATUS = {
  0: 'Approved',
  1: 'Rejected',
};

export const ORDER_STATUS = {
  PENDING_PAYMENT: 1,
  PAID: 2,
  SHIPPED: 3,
  CANCELLED: 4,
  PROCESSING: 5,
  BUILD_SHAFT: 6,
  ON_HOLD: 7,
  PARTIALLY_SHIPPED: 12
};

export const PAYMENT_METHOD = [
  { value: "Card On File", key: 1 },
  { value: "Cheque", key: 2 },
  { value: "ACH/Bank transfer", key: 3 },
  { value: "Cash", key: 4 },
  { value: "PayPal", key: 6 },
  { value: "Credit Card", key: 7 },
  { value: "Others", key: 5 },
];

export const ORDER_LIST_PAYMENT_METHOD = {
  1: 'Card On File',
  2: 'Cheque',
  3: "ACH/Bank transfer",
  4: "Cash",
  5: "Others",
  6: "PayPal",
  7: "Credit Card",
};

export const ORDERS_PAYMENT_STATUS = [
  { value: 'PENDING', key: 1 },
  { value: 'PAID', key: 2 },
  { value: 'NET05', key: 3 },
  { value: 'NET15', key: 4 },
  { value: 'NET30', key: 5 },
  { value: 'NET45', key: 6 },
  { value: 'NET60', key: 7 },
  { value: 'NET90', key: 8 },
];

export const SHIPPING_CATEGORY_NAME = {
  1: "Kinetixx Shipping",
  2: "Customer Billing",
  3: "Merge With Existing Order",
  4: "Freight Shipping",
}

export const SHIPPING_CATEGORY = {
  "kinetixx_shipping_service": 1,
  "customer_shipping_service": 2,
  "merge_shipping": 3,
  "freight_shipment": 4,
}

export const SERVICE_SHIPPING = {
  "no_shipping": 1,
  "kinetixx_shipping": 3,
  "customer_shipping_service": 2,
  "freight_shipment": 4,
}

export const ORDER_LIST_ORDER_STATUS = [
  { value: 'PENDING PAYMENT', key: 1 },
  { value: 'NEW', key: 2 },
  { value: 'PROCESSING', key: 5 },
  { value: 'BUILD SHOP', key: 6 },
  { value: 'SHIPPED', key: 3 },
  { value: 'DELIVERED', key: 8 },
  { value: 'RETURN INITIATED', key: 9 },
  { value: 'RETURNED', key: 10 },
  { value: 'REFUNDED', key: 11 },
  { value: 'ON HOLD', key: 7 },
  { value: 'CANCELLED', key: 4 },
  { value: 'PARTIALLY SHIPPED', key: 12 }
];

export const ORDER_LIST_ORDER_MANAGEMENT_ORDER_STATUS = [
  { value: 'PENDING PAYMENT', key: 1 },
  { value: 'PAID', key: 2 },
  { value: 'PROCESSING', key: 5 },
  { value: 'BUILD SHOP', key: 6 },
  { value: 'SHIPPED', key: 3 },
  { value: 'DELIVERED', key: 8 },
  { value: 'RETURN INITIATED', key: 9 },
  { value: 'RETURNED', key: 10 },
  { value: 'REFUNDED', key: 11 },
  { value: 'ON HOLD', key: 7 },
  { value: 'CANCELLED', key: 4 },
  { value: 'PARTIALLY SHIPPED', key: 12 }
];

export const ORDER_LIST_SHIPPING_MANAGEMENT_ORDER_STATUS = [
  { value: 'PROCESSING', key: 5 },
];
export const ORDER_LIST_BUILD_SHOP_MANAGEMENT_ORDER_STATUS = [
  { value: 'BUILD SHOP', key: 6 },
];

export const VIEW_ORDER_LIST_ORDER_STATUS = {
  1: 'PENDING PAYMENT',
  2: 'NEW',
  3: 'SHIPPED',
  4: 'CANCELLED',
  5: 'PROCESSING',
  6: 'BUILD SHOP',
  7: 'ON HOLD',
  8: 'DELIVERED',
  9: 'RETURN INITIATED',
  10: 'RETURNED',
  11: 'REFUNDED',
  12: 'PARTIALLY SHIPPED'
};

export const SHIPPING_BOX_STATUS = [
  'PENDING SHIPMENT',
  'SHIPPED',
  'DELIVERED',
  'RETURN INITIATED',
  'RETURNED',
  'REFUNDED'
]

export const VOID_STATUS = [
  'PROCESSING',
  'VOIDED',
  'REJECTED'
]

export const DATE_FILTER_OPTIONS = [
  'equals',
  'inRange',
  'lessThan',
  'greaterThan',
];

export const TEXT_FILTER_OPTIONS = ['contain'];
export const NUMBER_FILTER_OPTIONS = [
  'equals',
  'notEqual',
  'inRange',
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
];

export const ALERT = {
  SUCCESS: 'success',
  DANGER: 'danger',
};

export const METHOD = {
  POST: 'POST',
  GET: 'GET',
};

export const CKEditorConfig = {
  height: 150,
  versionCheck: false,
  removeButtons: '',
  toolbar: [
    ['Cut', 'Copy', 'Paste'],
    ['Undo', 'Redo'],
    ['SpellChecker'],
    ['Link', 'Unlink', 'Anchor'],
    [
      'Image',
      'Table',
      'Horizontal Line',
      'Special Character',
    ],
    ['Maximize'],
    ['Source'],
    ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript'],
    ['RemoveFormat'],
    ['NumberedList', 'BulletedList'],
    ['DecreaseIndent', 'IncreaseIndent'],
    ['BlockQuote'],
    ['Styles'],
    ['Format'],
    ['About'],
    ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
  ],
  extraPlugins: 'justify',
}

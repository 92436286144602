/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { create } from 'actions/admin/staff';
import Errors from 'views/Notifications/Errors';
import ReactTooltip from 'react-tooltip';
import {
  PASSWORD_DEFAULT_LENGTH,
  UI_PERMISSION_NAME,
  PERMISSION_NAME,
} from 'constants/index';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormText
} from 'reactstrap';
import usePassword from 'utils/usePasswordHook';

const CreateStaff = ({ create, history, errorList, loading, location }) => {
  const navigate = useNavigate(); // make const
  const inputRef = useRef(null);
  const [checkPassword, setCheckPassword] = useState(false);
  /********************************generate password ********************/

  //   randomstring.generate(PASSWORD_DEFAULT_LENGTH)
  // );


  /********************************usestate form data ********************/
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    permission: [],

    //role: history.location.pathname === '/admin/staff/create' ? 5 : 2,
  });

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  //   history.location.pathname === '/admin/staff/create' ? true : false;
  // /************************explode form data ********************/
  const { first_name, last_name, email, password, permission } = formData;

  /***********************use dispatch ********************/

  /***********************on change event ********************/
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setCheckPassword(false);
  };

  let genpass = usePassword();
  useEffect(() => {
    if (genpass) {
      setFormData({ ...formData, password: genpass })
      ReactTooltip.show(inputRef.current);
    }
  }, [genpass]);

  const handlePermissionCheckedChange = (e) => {
    let permissionArray = formData['permission'];
    if (parseInt(e.target.name.trim()) === PERMISSION_NAME['SUPER_ADMIN']) {
      if (e.target.checked) {
        permissionArray = [1, 2, 3, 4, 5, 6];
        setIsSuperAdmin(true);
      } else {
        permissionArray = [];
        setIsSuperAdmin(false);
      }
    } else {
      setIsSuperAdmin(false);
      if (e.target.checked) {
        if (!permissionArray.length)
          permissionArray.push(parseInt(e.target.name.trim()));
        else {
          permissionArray = permissionArray.filter((item, index) => {
            return item !== parseInt(e.target.name.trim());
          });
          permissionArray.push(parseInt(e.target.name.trim()));
        }
      } else {
        if (permissionArray.length) {
          permissionArray = permissionArray.filter((item, index) => {
            return item !== parseInt(e.target.name.trim());
          });
        }
      }
    }
    setFormData({ ...formData, permission: permissionArray });
  };
  /***********************on change for select role ********************/
  const selectRole = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: ACCESS_ROLE[e.target.value.trim()],
    });
  };

  /***********************on submit event ********************/
  const onSubmit = (e) => {
    e.preventDefault();
    Object.keys(formData).forEach((k) => {
      typeof formData[k] === 'string'
        ? (formData[k] = formData[k]?.trim())
        : (formData[k] = formData[k]);
    });
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
    const isChecked = regex.test(password);
    if (isChecked) {
      create(formData, navigate);
      setCheckPassword(false);
    } else {
      setCheckPassword(true);
    }
  };

  const backRoute = () => {
    navigate(-1);
  };

  return (
    <div className="animated fadeIn">
      <Form
        className="form-horizontal"
        autoComplete="false"
        onSubmit={(e) => onSubmit(e)}
      >
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <h5>Basic Information</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="first_name">
                        First Name <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="first_name"
                        name="first_name"
                        maxLength="25"
                        value={first_name}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.first_name ? true : false}
                      />
                      <Errors current_key="first_name" key="first_name" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="last_name">Last Name</Label>
                      <Input
                        type="text"
                        id="last_name"
                        name="last_name"
                        maxLength="25"
                        value={last_name}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.last_name ? true : false}
                      />
                      <Errors current_key="last_name" key="last_name" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="email">
                        Email <span>*</span>
                      </Label>
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        maxLength="50"
                        value={email}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.email ? true : false}
                      />
                      <Errors current_key="email" key="email" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="password">
                        Password <span>*</span>
                      </Label>
                      {password == '' &&
                        <ReactTooltip id="title required" efect="solid" place="bottom">
                          Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                        </ReactTooltip>
                      }
                      <Input
                        type="text"
                        id="password"
                        name="password"
                        ref={inputRef}
                        data-tip
                        data-for="title required"
                        value={password}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.password ? true : false}
                      />
                      <Errors current_key="password" key="password" />
                      {(checkPassword && password != "") &&
                        <div id="message">
                          <FormText style={{ fontSize: "10px" }} className='text-danger'> Password requirements:</FormText>
                          <FormText>
                            <li style={{ fontSize: "10px" }} className='text-danger'>Must contain at least 8 characters</li>
                            <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 uppercase letter</li>
                            <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 number</li>
                            <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 special character (@$!%*#?&.)</li>
                          </FormText>
                        </div>
                      }
                    </FormGroup>
                  </Col>
                  <Col sm="12">
                    <Label htmlFor="permission">
                      Permissions <span>*</span>
                    </Label>
                    <Row>
                      {Object.entries(UI_PERMISSION_NAME).map(
                        ([key, value]) => {
                          return (
                            <Col>
                              <FormGroup className="checkouter">
                                <Input
                                  type="checkbox"
                                  id={key}
                                  name={key}
                                  className="mr-2"
                                  checked={permission.includes(parseInt(key))}
                                  onChange={(e) =>
                                    handlePermissionCheckedChange(e)
                                  }
                                  disabled={
                                    isSuperAdmin &&
                                    parseInt(key) !==
                                    PERMISSION_NAME['SUPER_ADMIN']
                                  }
                                />{' '}
                                <Label for={key}> {value}</Label>
                              </FormGroup>
                            </Col>
                          );
                        }
                      )}
                    </Row>
                    <Errors current_key="permission" key="permission" />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardFooter>
                <Button
                  className="mr-05"
                  type="submit"
                  size="sm"
                  color="primary"
                  disabled={loading}
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
                <Button
                  type="reset"
                  size="sm"
                  color="danger"
                  onClick={backRoute}
                >
                  <i className="fa fa-ban"></i> Cancel
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

CreateStaff.propTypes = {
  create: PropTypes.func.isRequired,
  staff: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  staff: state.staff,
  loading: state.staff.loading,
  errorList: state.errors,
});

export default connect(mapStateToProps, { create })(CreateStaff);
